import { Helmet } from "react-helmet";
import React from 'react';
const SEO = ({ basicSEO, jsonLD }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            {/* basic seo */}
            <title>{basicSEO.title}</title>
            <meta name="description" content={basicSEO.description} />
            {/* basic seo */}

            {/* og */}
            <meta content={basicSEO.title} property="og:title" />
            <meta content={basicSEO.description} property="og:description" />
            <meta content={basicSEO.title} name="twitter:title" />
            <meta content={basicSEO.description} name="twitter:description" />
            <meta content="website" property="og:type" />
            <meta content={basicSEO.title} property="og:site_name" />
            <meta content="summary_large_image" name="twitter:card" />

            {/* fb og tags */}
            <meta property="og:image" content="https://hotelgracegalaxy.com/premium-hotel-room-bandra-mumbai.jpg" />

            {/* canonical url */}
            <meta content={basicSEO.title} name="twitter:text:title" />
            <meta content={basicSEO.canonical} property="og:url" />
            <meta content={basicSEO.canonical} name="twitter:url" />
            <link rel="canonical" href={basicSEO.canonical} />
            {/* end canonical url */}

            {/* end og */}
            <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
        </Helmet>
    )
}
export default SEO;